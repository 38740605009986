import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Chip, SectionForm, Text } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import dot from 'utils/dot';
import { errorToast } from 'utils/toast';

import { getAutomationVariables } from 'services/AutomationsService';
import {
    findNodeByType,
    CONDITION_BOOL_LOGIC_LOCALES,
    CONDITION_OPERATOR_LOCALES,
    buildPathTree,
    CONDITION_TYPE_NO_INPUT,
} from '../../../../utils';
import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';

import DeleteModal from 'components/DeleteModal';
import AutomationBuilderBox from '../../components/AutomationBuilderBox';
import ConditionBoxValue from './ConditionBoxValue';

const AutomationBuilderConditionBox = memo((props) => {
    const { data, onChange, dispatch } = useContext(AutomationBuilderCanvasContext);
    const deleteModalRef = useRef();
    const nodeData = useMemo(() => findNodeByType(data?.scene, props.id), [data?.scene, props.id]);
    const [entityFieldsMap, setEntityFieldsMap] = useState();

    const onDeleteRef = useCallback((ref) => {
        deleteModalRef.current = ref;
    }, []);

    const onDelete = useCallback(() => {
        deleteModalRef.current.open();
    }, []);

    const onDeleteConfirm = useCallback(() => {
        dispatch({ type: 'close' });
        const nodePath = dot.findPathByAttr(data, 'name', props.id);
        onChange(nodePath, {
            name: 'conditions_logic',
            parameters: {
                conditions: undefined,
            },
            type: 'conditions',
        });
        return Promise.resolve();
    }, [data, dispatch, onChange, props.id]);

    const hasData = useMemo(() => {
        return (
            !isEmptyObject(nodeData?.parameters?.conditions) &&
            !nodeData?.parameters?.conditions?.parameters?.variable &&
            entityFieldsMap
        );
    }, [entityFieldsMap, nodeData?.parameters?.conditions]);

    useEffect(() => {
        if (hasData) return;
        const nodePath = dot.findPathByAttr(data, 'name', props.id);
        const nodeBranch = buildPathTree(data, nodePath);
        getAutomationVariables(nodeBranch?.scene)
            .then((result) => {
                const newEntityFieldsMap = {};

                result?.variables?.related?.forEach((related) => {
                    newEntityFieldsMap[related.value] = ` (${getLiteral(related?.description)})`;
                });

                setEntityFieldsMap(newEntityFieldsMap);
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [data, entityFieldsMap, hasData, props.id]);

    const conditions = useMemo(() => {
        if (!hasData) return null;
        const conditions = nodeData?.parameters?.conditions;
        const checkLast = (idx, arr) => idx < arr.length - 1;
        return conditions?.parameters?.map((group, index) => {
            const hasConditions = !!group?.parameters?.length;

            return (
                <>
                    <SectionForm
                        className="fm-automation-builder__condition-box__groups"
                        title={`${getLiteral('label_conditions_group')} ${index + 1}`}
                        isExpandable={false}
                    >
                        {!hasConditions && (
                            <Text type="subtitle">
                                {getLiteral('action_automation_add_condition')}
                            </Text>
                        )}
                        {hasConditions &&
                            group?.parameters?.map((condition, index2) => {
                                const operator = condition?.type;
                                const variable = condition?.parameters?.variable;
                                return (
                                    <>
                                        <Text type="subtitle" medium>
                                            {getLiteral(variable?.literal)}
                                            {
                                                entityFieldsMap[
                                                    variable?.value
                                                        .split('.')
                                                        .slice(0, -1)
                                                        .join('.')
                                                ]
                                            }
                                        </Text>
                                        <div className="fm-automation-builder__condition-box__row-value">
                                            <Text color="neutral700">
                                                {getLiteral(CONDITION_OPERATOR_LOCALES[operator])}
                                                {!CONDITION_TYPE_NO_INPUT.includes(operator) && ':'}
                                            </Text>
                                            <ConditionBoxValue
                                                field={variable}
                                                condition={condition}
                                                operator={operator}
                                            />
                                        </div>
                                        {checkLast(index2, group?.parameters) && (
                                            <div className="fm-automation-builder__condition-box__row-logic">
                                                <Chip isActive isFilled>
                                                    {getLiteral(
                                                        CONDITION_BOOL_LOGIC_LOCALES[group?.type],
                                                    )}
                                                </Chip>
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                    </SectionForm>
                    {checkLast(index, conditions?.parameters) && (
                        <div className="fm-automation-builder__condition-box__row-logic">
                            <Chip isActive isFilled>
                                {getLiteral(CONDITION_BOOL_LOGIC_LOCALES[conditions?.type])}
                            </Chip>
                        </div>
                    )}
                </>
            );
        });
    }, [entityFieldsMap, hasData, nodeData?.parameters?.conditions]);

    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className={classnames('fm-automation-builder__condition-box', {
                    'fm-automation-builder__condition-box--filled': hasData,
                })}
                title={getLiteral('label_condition')}
                icon="deviceHub"
                onDelete={hasData ? onDelete : undefined}
                deleteTooltip={getLiteral('action_delete_conditions')}
                emptyPlaceholder={getLiteral('action_automation_add_condition')}
            >
                {conditions}
            </AutomationBuilderBox>
            <Handle type="source" position={Position.Bottom} id="bottom" isConnectable={false} />
            <DeleteModal
                overlayClassName="fm-automation-builder__modal"
                title={getLiteral('label_delete_all_conditions')}
                body={getLiteral('label_delete_all_conditions_desc')}
                confirmText={getLiteral('action_delete_all_conditions')}
                onRef={onDeleteRef}
                onDelete={onDeleteConfirm}
            />
        </>
    );
});

export default AutomationBuilderConditionBox;
