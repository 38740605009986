import React, { memo, useCallback, useRef, useState, useMemo } from 'react';
import { Tabs } from 'hoi-poi-ui';
import { Route, Routes } from 'react-router-dom';
import { RouteOnMount } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import SettingsLayout from '../components/SettingsLayout';
import AutomationsList from './AutomationsList';
import AutomationsLog from './AutomationsLog';
import AutomationBuilder from './components/AutomationBuilder';

import './styles.scss';

const AutomationSettings = memo(() => {
    const modalRef = useRef(null);
    const [activeTab, setActiveTab] = useState('automations-list');

    const onModalRef = useCallback((ref) => {
        modalRef.current = ref;
    }, []);

    const openBuilder = useCallback((...props) => {
        modalRef.current?.open(...props);
    }, []);

    const tabs = useMemo(
        () => [
            {
                key: 'automations-list',
                title: getLiteral('label_automations'),
            },
            {
                key: 'automations-log',
                title: getLiteral('label_automation_history'),
            },
        ],
        [],
    );

    return (
        <SettingsLayout
            className="fm-automation-settings"
            title={getLiteral('label_automations')}
            description={getLiteral('label_automations_desc')}
            withoutDivider
        >
            <Routes>
                <Route
                    path="/:id"
                    element={
                        <RouteOnMount
                            onMount={(params) => {
                                if (!params.id) return;
                                modalRef.current?.open(params.id);
                            }}
                        />
                    }
                />
            </Routes>
            <Tabs onChange={setActiveTab} activeKey={activeTab} tabs={tabs} />
            {activeTab === 'automations-list' && <AutomationsList openBuilder={openBuilder} />}
            {activeTab === 'automations-log' && <AutomationsLog openBuilder={openBuilder} />}
            <AutomationBuilder onRef={onModalRef} />
        </SettingsLayout>
    );
});

export default AutomationSettings;
