import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { SectionForm, Input, Slider, DatePicker, Divider, Link, Spacer } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import { ModalOptions } from '@web/web5';
import SelectHoi from 'components/SelectHoi';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { formatDate } from 'utils/dates';
import {
    ACTION_OPERATORS,
    ACTION_OPERATORS_DEFAULTS,
    ACTION_OPERATORS_NO_INPUT,
} from '../../../../../../utils';

import Select from 'containers/components/Fields/Select';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';
import MultiSelect from 'containers/components/Fields/MultiSelect';
import MultiSelectFuzzy from 'containers/components/Fields/MultiSelectFuzzy';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import CustomizationIllustration from 'components/illustrations/CustomizationIllustration';

import './styles.scss';

const UpdateEntityRelatedAction = memo(({ onChange, form, action, variables }) => {
    const lang = useSelector((state) => state.config?.userData?.langISOInterface);
    const [fields, setFields] = useState([]);
    const [operators, setOperators] = useState({});
    const [modalFieldsOpen, setModalFieldsOpen] = useState(false);
    const [selectedFields, setSelectedFields] = useState({});
    const boolOptions = useMemo(
        () => [
            {
                label: getLiteral('cfm_label_yes'),
                value: true,
            },
            {
                label: getLiteral('cfm_label_no'),
                value: false,
            },
        ],
        [],
    );

    useEffect(() => {
        if (!variables) return;
        let fields = variables?.values?.fields[form.parameters?.entity?.original?.entity];

        setFields(
            fields?.map((item) => {
                return {
                    label: item.name,
                    id: item.name,
                    options:
                        item.fields?.map?.((field) => ({
                            ...field,
                            label: getLiteral(field.literal),
                            id: field.key,
                            original: field,
                        })) || [],
                };
            }) || [],
        );

        if (form.fields?.length > 0) {
            const newSelectedFields = {};
            const fieldsBySection = {};
            form.fields.forEach((field) => {
                const fieldKey = field.key;
                fields?.forEach((section) => {
                    const foundField = section.fields?.find((f) => f.key === fieldKey);
                    if (foundField) {
                        if (!fieldsBySection[section.name]) {
                            fieldsBySection[section.name] = [];
                        }
                        fieldsBySection[section.name].push(fieldKey);
                    }
                });
            });

            Object.keys(fieldsBySection).forEach((sectionName) => {
                const sectionId = fields?.find((section) => section.name === sectionName)?.name;
                if (sectionId) {
                    newSelectedFields[sectionId] = {
                        isSelected: false,
                        options: fieldsBySection[sectionName],
                    };
                }
            });

            setSelectedFields(newSelectedFields);
        }

        setOperators(variables?.operators);
    }, [form.parameters?.entity?.original?.entity, variables, form]);

    const changeValues = useCallback(
        (field) => (value) => {
            onChange((current) => {
                return {
                    ...current,
                    values: {
                        ...(current?.values || {}),
                        [field]: value,
                    },
                };
            });
        },
        [onChange],
    );

    const changeOperator = useCallback(
        (field) => (value) => {
            onChange((current) => {
                return {
                    ...current,
                    operators: { ...current.operators, [field]: value },
                    values: {
                        ...(current?.values || {}),
                        [field]: undefined,
                    },
                };
            });
        },
        [onChange],
    );

    const getFieldByType = useCallback(
        (field) => {
            let list, listProps, Component;
            switch (field.type) {
                case 'text':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            placeholder={getLiteral('placeholder_text_field')}
                            isFullWidth
                        />
                    );
                case 'int':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            type="integer"
                            placeholder={getLiteral('placeholder_numeric')}
                            isFullWidth
                        />
                    );
                case 'currency':
                case 'decimal':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            type="decimal"
                            placeholder={getLiteral('placeholder_numeric')}
                            isFullWidth
                        />
                    );
                case 'percentage':
                    return (
                        <Slider
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            isPercentage
                            isFullWidth
                        />
                    );
                case 'bit':
                    return (
                        <SelectHoi
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            options={boolOptions}
                            usePlainValue
                            isFullWidth
                            useMenuPortal={false}
                        />
                    );
                case 'datetime':
                    return (
                        <DatePicker
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            lang={lang}
                            calendarButtonLabel={getLiteral('label_today')}
                            placeholder={getLiteral('label_selectone')}
                            formatDate={(date) => formatDate(date, 'L')}
                            isFullWidth
                        />
                    );
                case 'list':
                    list = field.table?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    Component = listProps?.list ? SelectFuzzy : Select;
                    return (
                        <Component
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            cacheOptions={false}
                            list={field?.table?.table}
                            {...listProps}
                            useMenuPortal={false}
                            usePlainValue
                        />
                    );
                case 'multivalue':
                    list = field.table?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;
                    return (
                        <Component
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            list={field?.table?.table}
                            {...listProps}
                            useMenuPortal={false}
                            usePlainValue
                        />
                    );
                default:
                    return null;
            }
        },
        [boolOptions, changeValues, form?.values, lang],
    );

    const showFieldsModal = useCallback(() => {
        setModalFieldsOpen(true);
    }, []);

    const handleOnClose = useCallback(() => {
        setModalFieldsOpen(false);
    }, []);

    const onChangeSelectedFields = useCallback((selected) => {
        setSelectedFields(selected);
    }, []);

    const onSelectFields = useCallback(
        (selected) => {
            const fieldsMap = fields.reduce((obj, section) => {
                section.options.forEach((option) => (obj[option.key] = option));
                return obj;
            }, {});

            const fieldsList = Object.entries(selected).reduce((obj, [_, value]) => {
                value.options.forEach((option) => {
                    obj.push({
                        ...fieldsMap[option],
                        label: getLiteral(fieldsMap[option]?.literal),
                        value: fieldsMap[option]?.key,
                        original: fieldsMap[option]?.original,
                    });
                });
                return obj;
            }, []);

            onChange((current) => {
                return {
                    ...current,
                    fields: fieldsList,
                    operators: {
                        ...current.operators,
                        ...fieldsList?.reduce((obj, value) => {
                            if (current.operators?.[value?.value]) return obj;
                            else {
                                obj[value?.value] = ACTION_OPERATORS_DEFAULTS[value?.type];
                            }
                            return obj;
                        }, {}),
                    },
                };
            });

            setModalFieldsOpen(false);

            setSelectedFields(selected);
        },
        [fields, onChange],
    );

    const fieldsForm = useMemo(() => {
        return form?.fields?.map((field) => {
            const operatorOptions = operators?.[field.original.type]?.map((operator) => ({
                label: getLiteral(ACTION_OPERATORS[operator]),
                value: operator,
            }));

            const hasInput = !ACTION_OPERATORS_NO_INPUT.includes(form?.operators?.[field.value]);

            return (
                <SectionForm title={getLiteral(field.literal)} isExpandable>
                    <SelectHoi
                        options={operatorOptions}
                        value={form?.operators?.[field.value]}
                        onChange={changeOperator(field.value)}
                        useMenuPortal={false}
                        isFullWidth
                        usePlainValue
                    />
                    {hasInput && getFieldByType(field)}
                </SectionForm>
            );
        });
    }, [form?.fields, form?.operators, operators, changeOperator, getFieldByType]);

    const sectionTitle = useMemo(() => {
        if (!form?.parameters?.entity) return;
        return `${action?.label} ${form?.parameters?.entity?.label}`;
    }, [action?.label, form]);

    const modalOptions = useMemo(() => {
        return {
            overlayClassName: 'fm-automation__update-entity-related-action__modal-overlay',
            title: getLiteral('cfm_action_add_field'),
            schema: fields,
            selected: selectedFields,
            isOpen: modalFieldsOpen,
            onChangeSelected: onChangeSelectedFields,
            placeholderSearch: getLiteral('label_search_field'),
            confirmText: getLiteral('action_save'),
            onConfirm: onSelectFields,
            cancelText: getLiteral('action_cancel'),
            onCancel: handleOnClose,
        };
    }, [
        fields,
        handleOnClose,
        modalFieldsOpen,
        onChangeSelectedFields,
        onSelectFields,
        selectedFields,
    ]);

    const showFields = form?.fields?.length > 0;

    return (
        <>
            {form?.parameters?.entity && (
                <SectionForm title={sectionTitle} isExpandable={false}>
                    <div className="fm-automation__update-entity-related-action">
                        {!showFields && (
                            <AbsoluteEmpty
                                svg={<CustomizationIllustration />}
                                title={getLiteral('label_automations_action_update_select_field')}
                                subtitle={getLiteral(
                                    'label_automations_action_update_select_field_desc',
                                )}
                                size="small"
                            />
                        )}
                        {fieldsForm}
                        <Spacer y={2} />
                        <Divider />
                        <div className="fm-automation__update-entity-related-action__footer">
                            <Link variation="primary" onClick={showFieldsModal} bold>
                                + {getLiteral('cfm_action_add_field')}
                            </Link>
                        </div>
                    </div>
                    <ModalOptions {...modalOptions} />
                </SectionForm>
            )}
        </>
    );
});

UpdateEntityRelatedAction.getInitialData = (node) => {
    return {
        ...node,
        name: node?.parameters?.entity ? node.name : undefined,
        parameters: {
            ...node.parameters,
            entity: node?.parameters?.entity
                ? {
                      label: getLiteral(node.parameters.entity?.literal),
                      value: node.parameters.entity?.value,
                      iconType: node.parameters.entity?.entity,
                      original: node.parameters.entity,
                  }
                : undefined,
        },
        fields: node.parameters?.values?.map((value) => ({
            ...value?.field,
            label: getLiteral(value?.field?.literal),
            value: value?.field?.key,
            original: value?.field,
        })),
        operators: node.parameters?.values?.reduce((obj, value) => {
            obj[value.field?.key] = value?.value?.type;
            return obj;
        }, {}),
        values: node.parameters?.values?.reduce((obj, value) => {
            obj[value.field?.key] = value?.value?.value;
            return obj;
        }, {}),
    };
};

UpdateEntityRelatedAction.hasEmptyFields = (form) => {
    if (!form.fields?.length) return true;
    return form.fields.some((field) => {
        const operatorExcluded = ACTION_OPERATORS_NO_INPUT.includes(form.operators[field.value]);
        const valueIsUndefined = !form.values[field.value];
        return !operatorExcluded && valueIsUndefined;
    });
};

UpdateEntityRelatedAction.getSaveData = (form) => {
    let values = [];
    if (form.fields?.length > 0) {
        values = form.fields.map((field) => ({
            field: field.original,
            value: {
                type: form.operators[field.value],
                value: form.values[field.value],
            },
        }));
    }
    return { entity: form.parameters.entity.original, values };
};

export default UpdateEntityRelatedAction;
