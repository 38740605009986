import { Map } from 'immutable';
import Context from 'managers/Context';
import { COMPANIES } from 'constants/Entities';
import { getCrudFieldConfig, getCrudFieldId } from 'utils/fm/fields';
import { AddressModel } from 'models';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { getCustomizeCrudAction } from 'utils/crud';
import { isEmptyObject } from 'utils/objects';
import { getActiveCrud } from 'utils/crud';
import { generateRange, weirdFormatDateForServer } from 'utils/dates';
import { translateFollowingItemForBackend } from 'utils/filters';
import { COMPANY_ICON } from 'constants/Images';

const mergeAccountsSchema = () => [
    {
        description: getLiteral('label_informations'),
        show: false,
        tabFields: [
            {
                id: 'Name',
                server: 'nombre',
                mandatoryReadOnlyField: 'nombre',
                description: getLiteral('label_account_name'),
                title: true,
            },
            {
                id: 'Phone',
                server: 'tel',
                mandatoryReadOnlyField: 'tel',
                description: getLiteral('label_phone'),
            },
            {
                id: 'Phone2',
                server: 'tel2',
                mandatoryReadOnlyField: 'tel2',
                description: getLiteral('label_phone2'),
            },
            {
                id: 'CellPhone',
                server: 'tel_movil',
                mandatoryReadOnlyField: 'strmovil',
                description: getLiteral('label_mobilephone'),
            },
            {
                id: 'Fax',
                server: 'fax',
                mandatoryReadOnlyField: 'fax',
                description: getLiteral('label_fax'),
            },
            {
                id: 'Email1',
                server: 'email',
                mandatoryReadOnlyField: 'email',
                description: getLiteral('label_email'),
            },
            {
                id: 'Web',
                server: 'web',
                mandatoryReadOnlyField: 'web',
                description: getLiteral('label_web'),
            },
            {
                id: 'NIF',
                server: 'nif',
                mandatoryReadOnlyField: 'nif',
                description: getLiteral('label_nif'),
            },
            {
                id: 'Level',
                server: 'nivel',
                mandatoryReadOnlyField: 'nivel',
                description: getLiteral('label_level'),
            },
            {
                id: {
                    id: 'IdCompanyType',
                    desc: 'CompanyType',
                },
                server: 'idTipoEmpresa',
                mandatoryReadOnlyField: 'idTipo',
                description: getLiteral('label_account_type'),
            },
            {
                id: {
                    id: 'IdCompanyState',
                    desc: 'CompanyState',
                },
                server: 'idEstadoEmpresa',
                mandatoryReadOnlyField: 'idEstadoEmpresa',
                description: getLiteral('label_status'),
            },
            {
                id: {
                    id: 'IdEnvironment',
                    desc: 'Environment',
                },
                server: 'idSucursal',
                mandatoryReadOnlyField: 'idSucursal',
                description: getLiteral('label_environment'),
            },
            {
                id: {
                    id: 'Idcalificacion',
                    desc: 'Calificacion',
                },
                server: 'idCalificacion',
                mandatoryReadOnlyField: 'idCalificacion',
                description: getLiteral('label_account_calification'),
            },
            {
                id: {
                    id: 'IdResponsable',
                    desc: 'Responsible',
                },
                server: 'idComercial',
                mandatoryReadOnlyField: 'idComercial',
                description: getLiteral('label_responsible_1'),
            },
            {
                id: {
                    id: 'IdResponsable2',
                    desc: 'Responsible2',
                },
                server: 'idComercial2',
                mandatoryReadOnlyField: 'idComercial2',
                description: getLiteral('label_responsible_2'),
            },
            {
                id: {
                    id: 'IdResponsable3',
                    desc: 'Responsible3',
                },
                server: 'idComercial3',
                mandatoryReadOnlyField: 'idComercial3',
                description: getLiteral('label_responsible_3'),
            },
            {
                id: {
                    id: 'IdResponsable4',
                    desc: 'Responsible4',
                },
                server: 'idComercial4',
                mandatoryReadOnlyField: 'idComercial4',
                description: getLiteral('label_responsible_4'),
            },
            {
                id: {
                    id: 'IdResponsable5',
                    desc: 'Responsible5',
                },
                server: 'idComercial5',
                mandatoryReadOnlyField: 'idComercial5',
                description: getLiteral('label_responsible_5'),
            },
            {
                id: {
                    id: 'IdTarifa',
                    desc: 'tarifa',
                },
                server: 'idTarifa',
                mandatoryReadOnlyField: 'idTarifa',
                description: getLiteral('label_rate'),
            },
        ],
    },
    {
        description: getLiteral('label_address_detail'),
        show: true,
        tabFields: [
            {
                id: 'Address1',
                server: 'direccion',
                mandatoryReadOnlyField: 'direccion',
                description: getLiteral('label_adress_line1'),
            },
            {
                id: 'Address2',
                server: 'direccion2',
                mandatoryReadOnlyField: 'direccion2',
                description: getLiteral('label_adress_line2'),
            },
            {
                id: 'City',
                server: 'strPoblacion',
                mandatoryReadOnlyField: 'strPoblacion',
                description: getLiteral('label_city'),
            },
            {
                id: 'Province',
                server: 'strProvincia',
                mandatoryReadOnlyField: 'strProvincia',
                description: getLiteral('label_province_region'),
            },
            {
                id: 'CP',
                server: 'cp',
                mandatoryReadOnlyField: 'cp',
                description: getLiteral('label_post_code'),
            },
            {
                id: {
                    id: 'IdCountry',
                    desc: 'country',
                },
                server: 'idCountry',
                mandatoryReadOnlyField: 'idCountry',
                description: getLiteral('label_country'),
            },
            {
                id: 'Latitude',
                server: 'lat',
                mandatoryReadOnlyField: '',
                dataType: 'coordinate',
                description: getLiteral('label_latitude'),
            },
            {
                id: 'Longitude',
                server: 'lon',
                mandatoryReadOnlyField: '',
                dataType: 'coordinate',
                description: getLiteral('label_longitude'),
            },
            {
                id: 'Comments',
                server: 'Observaciones',
                mandatoryReadOnlyField: 'Observaciones',
                description: getLiteral('label_observations'),
            },
        ],
    },
];

function emptyFilterRate(item) {
    return !item.isDisabled;
}

export default class CompaniesManager {
    canAsyncExtraFields() {
        return true;
    }

    getMergeEntitySchema() {
        return mergeAccountsSchema();
    }

    getExtraFieldSchema(success, error) {
        this.context.extraFieldManager.getExtraFieldSchema(
            COMPANIES,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value['-id'], value);
                });
                success(resultSchema);
            },
            error,
        );
    }

    getCustomViewKey() {
        return 'sfm_companies_custom_view';
    }

    preloadFilters(filters) {
        const state = Context.store.getState();
        const companiesBulkActions = state.config.permission.companiesBulkActions;
        let createEventBulk = state.config?.permission?.createEventBulk;
        const enableBulkOperations =
            createEventBulk || companiesBulkActions || state.config?.permission?.allowSendEmails;

        let filtersToLoad = [];

        if (enableBulkOperations) {
            if (!filters?.filters?.entityShowSelected) {
                filtersToLoad = [
                    {
                        entity: COMPANIES,
                        filter: {
                            id: 'entityShowSelected',
                            hideForCount: true,
                            invisible: true, // Marked as internal filter only
                            noCacheCheck: true,
                        },
                        values: 'companies',
                    },
                ];
            }
        }

        return filtersToLoad;
    }

    getEntity(id, success, error, withExtraFields = false) {
        return this.context.domainManager.getEntity(
            id,
            COMPANIES.entity,
            withExtraFields,
            (data) => {
                let entity = { ...data };
                success(entity, data);
            },
            error,
        );
    }

    beforeDetail = () =>
        new Promise((resolve, reject) => {
            this.checkListValuesWithValues()
                .then(({ hasStates, hasRates }) => {
                    resolve({
                        canShowStateField: hasStates,
                        canShowRateField: hasRates,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    return reject(err);
                });
        });
    // FROM THIS - TO DEPRECATE
    getAddressCompany(id, callbackSuccess, callbackError) {
        this.context.domainManager.getCompanyAddress(
            id,
            (data) => {
                data = data.map((address) => new AddressModel(address));
                callbackSuccess(data);
            },
            callbackError,
        );
    }

    addCompanyAddress(entity, success, error) {
        this.context.domainManager.addCompanyAddress(entity, success, error);
    }

    updateCompanyAddress(entity, id, success, error) {
        this.context.domainManager.updateCompanyAddress(entity, id, success, error);
    }

    deleteCompanyAddress(id, success, error) {
        this.context.domainManager.deleteCompanyAddress(id, success, error);
    }
    // TO THIS - TO DEPRECATE

    getCompanyAddress(id) {
        if (!id) return Promise.reject('Id of a company is needed');
        return new Promise((resolve, reject) => {
            Context.domainManager.getCompanyAddress(id, resolve, reject);
        });
    }

    updateEntity(id, entity, success, error) {
        this.context.domainManager.updateEntity(COMPANIES.entity, id, entity, success, error);
    }

    getCompanyDiscounts(id, success, error) {
        this.context.domainManager.getCompanyDiscounts(
            id,
            (data) => {
                success(data.Result);
            },
            error,
        );
    }

    deleteCompanyDiscounts(id, success, error) {
        this.context.domainManager.deleteCompanyDiscounts(id, success, error);
    }

    setCompanyDiscounts(data, success, error) {
        this.context.domainManager.setCompanyDiscounts(data, success, error);
    }

    getFilterSchema = () => {
        const state = Context.store.getState();
        const currentUser = `${state.config.userData.nombre} ${state.config.userData.apellidos}`;
        let filterSchema = [
            {
                id: 'onlySelected',
                dataType: 'bool',
                description: getLiteral('label_campaigns_new_campaign_accounts_selected_only'),
            },
            {
                id: 'followingItem',
                dataType: 'singleValueList',
                description: getLiteral('label_following'),
                generateOptions: () => {
                    return [
                        { label: getLiteral('label_following'), value: '1' },
                        { label: getLiteral('label_not_following'), value: '2' },
                    ];
                },
            },
            {
                id: 'owner1',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible_1'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'owner2',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible_2'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'owner3',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible_3'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'owner4',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible_4'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'owner5',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible_5'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'environment',
                fieldConfiguration: 'idsucursal',
                dataType: 'singleValueList',
                description: getLiteral('label_environment'),
                inputAttrs: {
                    list: 'tblSucursales',
                },
            },
            {
                id: 'companyType',
                dataType: 'singleValueList',
                fieldConfiguration: 'idtipo',
                description: getLiteral('label_account_type'),
                inputAttrs: {
                    list: window?.DEBUG_FM?.filtersCache?.companyTypeList || 'tblTiposEmpresa',
                },
            },
            {
                id: 'companyState',
                dataType: 'singleValueList',
                fieldConfiguration: 'idestadoempresa',
                description: getLiteral('label_state'),
                groupFunction: this.groupStates,
                getExtraClassNameValue: this.getExtraClassNameStateValue,
                beforeRenderValueList: this.beforeRenderValueList,
                inputAttrs: {
                    list: 'tblEstadosEmpresa',
                },
            },
            {
                id: 'activity', // Activity
                dataType: 'singleValueList',
                isCustomRange: true,
                description: getLiteral('label_activity'),
                generateOptions: this.generateActivityOptions,
            },
            {
                id: 'nif',
                fieldConfiguration: 'nif',
                description: getLiteral('label_vat_number'),
                dataType: 'text',
                asExtra: true,
            },
            {
                id: 'rates',
                dataType: 'singleValueList',
                fieldConfiguration: 'idtarifa',
                description: getLiteral('label_rate'),
                inputAttrs: {
                    list: 'tblTarifas',
                },
            },
            {
                id: 'email',
                description: getLiteral('label_email'),
                dataType: 'text',
                asExtra: true,
            },
            {
                id: 'phone',
                description: getLiteral('label_phone'),
                dataType: 'text',
            },
            {
                id: 'strprovincia',
                description: getLiteral('label_province_region'),
                dataType: 'text',
                asExtra: true,
            },
            {
                id: 'strpoblacion',
                description: getLiteral('label_city'),
                dataType: 'text',
                asExtra: true,
            },
            {
                id: 'cp',
                description: getLiteral('label_postal_code'),
                dataType: 'text',
                asExtra: true,
            },
            {
                id: 'idcountry',
                dataType: 'singleValueList',
                description: getLiteral('label_country'),
                inputAttrs: {
                    list: 'tblCountries',
                },
                asExtra: true,
            },
            {
                id: 'idcalificacion',
                dataType: 'singleValueList',
                fieldConfiguration: 'idcalificacion',
                description: getLiteral('label_account_calification'),
                inputAttrs: {
                    list: 'tblTiposCalificacionEmpresa',
                },
                asExtra: true,
            },
            {
                id: 'fcreado',
                description: getLiteral('label_created'),
                dataType: 'date',
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'accounts-filter__creation-date-start',
                    last: 'accounts-filter__creation-date-end',
                },
            },
            {
                id: 'fmodificado',
                description: getLiteral('label_modified'),
                dataType: 'date',
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'accounts-filter__modified-date-start',
                    last: 'accounts-filter__modified-date-end',
                },
            },
            // Below are the filters related to the cross filters functionality of this entity
            // the one tagged as "isFakeCrossFilter" is to be shown as cross filter in the allowed entities
            // the ones tagged as "invisible" are standard filters of this entity that will be set from the
            // cross filters section of the matching entity (because UX & BE)
            {
                id: 'hasAccount',
                description: getLiteral('label_filters_cross_filters_has_account'),
                dataType: 'bool',
                isFakeCrossFilter: true,
            },
            {
                id: 'hasActivity',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasContact',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasOpportunity',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasOrder',
                dataType: 'bool',
                invisible: true,
            },
        ];

        if (state.config.userData.generalResponsableFilterWeb) {
            filterSchema.push({
                id: 'users',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
            });
        }

        if (state.config.permission.verCampaignsAdmin) {
            filterSchema.push({
                id: 'idCampaign',
                dataType: 'singleValueList',
                description: getLiteral('label_campaign'),
                inputAttrs: {
                    ...FuzzyMap.campaigns,
                },
                subfilterKey: 'campaignIds',
                avoidListAsObject: true,
            });
            filterSchema.push({
                id: 'campaignStatus',
                dataType: 'singleValueList',
                description: getLiteral('label_campaign_completed_customer'),
                generateOptions: () => {
                    return [
                        { label: getLiteral('label_see_completed_campaigns'), value: '1' },
                        { label: getLiteral('label_see_pending_completed_campaigns'), value: '2' },
                    ];
                },
                isSubFilter: true,
                campaignIds: true,
                customShowSubfilterCondition: (campaignIds) => campaignIds.length > 0,
            });
        }

        return filterSchema;
    };

    getSchema = (defaultInputSchema) => {
        const state = Context.store.getState();
        const isFreeTrial = state?.config?.subscriptionModeCode === 'free_trial';

        const schema = [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'name'),
                        type: 'text',
                        label: getLiteral('label_companyname'),
                        hint: getLiteral('label_companyname'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'branchId'),
                        type: 'singleValueList',
                        label: getLiteral('label_environment'),
                        hint: getLiteral('label_environment'),
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                        description: isFreeTrial && getLiteral('helptext_environment_accounts'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'typeId'),
                        type: 'singleValueList',
                        label: getLiteral('label_account_type'),
                        hint: getLiteral('label_account_type'),
                        inputAttrs: {
                            list: 'tblTiposEmpresa',
                            actions: getCustomizeCrudAction(
                                COMPANIES,
                                '/settings/values-list',
                                `?entity=${COMPANIES.trueName}&list=tblTiposEmpresa`,
                            ),
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'statusId'),
                        type: 'singleValueList',
                        label: getLiteral('label_status'),
                        hint: getLiteral('label_status'),
                        inputAttrs: {
                            list: 'tblEstadosEmpresa',
                            hideIfEmptyList: true,
                            groupFunction: this.groupStates,
                            getExtraClassNameValue: this.getExtraClassNameStateValue,
                            actions: getCustomizeCrudAction(
                                COMPANIES,
                                '/settings/values-list',
                                `?entity=${COMPANIES.trueName}&list=tblEstadosEmpresa`,
                            ),
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'segmentId'),
                        type: 'singleValueList',
                        label: getLiteral('label_calification'),
                        hint: getLiteral('label_calification'),
                        description: getLiteral('helpext_segment_desc'),
                        inputAttrs: {
                            list: 'tblTiposCalificacionEmpresa',
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'vatNumber'),
                        type: 'text',
                        label: getLiteral('label_vat_number'),
                        hint: getLiteral('label_vat_number'),
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idResponsibleMultiple',
                        type: 'multipleFuzzySearchSingle',
                        keyIds: '',
                        inputAttrs: {
                            max: 5,
                            labelButton: getLiteral('action_add_owner'),
                        },
                        inputs: [
                            {
                                ...getCrudFieldConfig(COMPANIES, 'salesRepId1'),
                                label: getLiteral('label_responsible_1'),
                                hint: getLiteral('label_responsible_1'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialCompanies,
                                },
                            },
                            {
                                ...getCrudFieldConfig(COMPANIES, 'salesRepId2'),
                                label: getLiteral('label_responsible_2'),
                                hint: getLiteral('label_responsible_2'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialCompanies,
                                },
                            },
                            {
                                ...getCrudFieldConfig(COMPANIES, 'salesRepId3'),
                                label: getLiteral('label_responsible_3'),
                                hint: getLiteral('label_responsible_3'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialCompanies,
                                },
                            },
                            {
                                ...getCrudFieldConfig(COMPANIES, 'salesRepId4'),
                                label: getLiteral('label_responsible_4'),
                                hint: getLiteral('label_responsible_4'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialCompanies,
                                },
                            },
                            {
                                ...getCrudFieldConfig(COMPANIES, 'salesRepId5'),
                                label: getLiteral('label_responsible_5'),
                                hint: getLiteral('label_responsible_5'),
                                inputAttrs: {
                                    ...FuzzyMap.comercialCompanies,
                                },
                            },
                        ],
                        hasInviteUsers: true,
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'rateId'),
                        type: 'singleValueList',
                        label: getLiteral('label_rate'),
                        hint: getLiteral('label_rate'),
                        inputAttrs: {
                            list: 'ratesValueList',
                            hideIfEmptyList: true,
                            emptyFilter: emptyFilterRate,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'public'),
                        type: 'bool',
                        label: getLiteral('placeholder_public_company'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'comment'),
                        type: 'textarea',
                        label: getLiteral('label_notes'),
                        hint: getLiteral('label_notes'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 0,
                            rowsMax: 15,
                        },
                    },
                ],
            },
            {
                title: getLiteral('label_recommendation'),
                show: true,
                hiddenForBulk: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'warningText',
                        serverId: 'warningText',
                        type: 'textarea',
                        label: getLiteral('label_recommendation'),
                        description: getLiteral('helptext_add_warning'),
                        hint: '',
                        inputAttrs: {
                            labelMode: 'vertical',
                            isHighlighted: true,
                            multiLine: true,
                            rows: 2,
                            rowsMax: 10,
                        },
                    },
                ],
            },
            {
                title: getLiteral('label_contact_details'),
                show: true,
                fields: [
                    {
                        id: 'multiplePhones',
                        type: 'multipleField',
                        label: getLiteral('label_phone_numbers'),
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'phone'),
                                type: 'text',
                                hint: getLiteral('label_phone1'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'phone2'),
                                type: 'text',
                                hint: getLiteral('label_phone2'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'phone3'),
                                type: 'text',
                                hint: getLiteral('label_phone3'),
                            },
                        ],
                    },

                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'fax'),
                        type: 'text',
                        label: getLiteral('label_fax'),
                        hint: getLiteral('label_fax'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'email'),
                        type: 'text',
                        label: getLiteral('label_email'),
                        hint: getLiteral('label_email'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'website'),
                        type: 'text',
                        label: getLiteral('label_web'),
                        hint: getLiteral('label_web'),
                    },
                ],
            },
            {
                title: getLiteral('label_address'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'address1'),
                        type: 'text',
                        label: getLiteral('label_address'),
                        hint: getLiteral('label_address'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(COMPANIES, 'address2'),
                        type: 'text',
                        label: getLiteral('label_address2'),
                        hint: getLiteral('label_address2'),
                    },
                    {
                        id: 'multipleCityProvince',
                        type: 'multipleField',
                        label: ' ',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'city'),
                                type: 'text',
                                hint: getLiteral('label_city'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'region'),
                                type: 'text',
                                hint: getLiteral('label_provinceregion'),
                            },
                        ],
                    },
                    {
                        id: 'multipleCPCountry',
                        type: 'multipleField',
                        label: ' ',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'postcode'),
                                type: 'text',
                                hint: getLiteral('label_post_code'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(COMPANIES, 'countryId'),
                                type: 'singleValueList',
                                hint: getLiteral('label_country'),
                                inputAttrs: {
                                    list: 'tblCountries',
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'geolocation',
                        fieldConfiguration: '',
                        type: 'geolocation',
                        label: '',
                        hiddenForBulk: true,
                        inputAttrs: {
                            latitude: getCrudFieldId(COMPANIES, 'latitude'),
                            longitude: getCrudFieldId(COMPANIES, 'longitude'),
                            isGeoLocated: 'isGeoLocated', // custom field created in model
                            address1: getCrudFieldId(COMPANIES, 'address1'),
                            address2: getCrudFieldId(COMPANIES, 'address2'),
                            city: getCrudFieldId(COMPANIES, 'city'),
                            province: getCrudFieldId(COMPANIES, 'region'),
                            cp: getCrudFieldId(COMPANIES, 'postcode'),
                            country: 'country',
                            idCountry: getCrudFieldId(COMPANIES, 'countryId'),
                            mapMarkerIcon: COMPANY_ICON,
                        },
                        inputs: [
                            {
                                ...getCrudFieldConfig(COMPANIES, 'latitude'),
                                type: 'decimal',
                                label: getLiteral('label_latitude'),
                            },
                            {
                                ...getCrudFieldConfig(COMPANIES, 'longitude'),
                                type: 'decimal',
                                label: getLiteral('label_longitude'),
                            },
                        ],
                    },
                ],
            },
        ];

        return schema;
    };

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) =>
        new Promise((resolve, reject) => {
            const state = Context.store.getState();
            const activeCrud = getActiveCrud(state);
            const isEditing = activeCrud && activeCrud.id;

            this.checkListValuesWithValues()
                .then(({ hasStates, hasRates }) => {
                    const permissions = Context.store.getState().config.permission;

                    schema.map((currElement, index) => {
                        schema[index].fields = currElement.fields.filter((field) => {
                            switch (field.id) {
                                case 'nif':
                                    if (!permissions.ShowVATNumber) return false;
                                    return true;
                                case 'fax':
                                    if (!permissions.ShowFAX) return false;
                                    return true;
                                case 'idEstadoEmpresa':
                                    return hasStates;
                                case 'idTarifa':
                                    return hasRates;
                                case 'warningText':
                                    if (
                                        !permissions.warnings ||
                                        !permissions.viewWarningsInEmpresas
                                    )
                                        return false;
                                    if (!permissions.createWarnings && !permissions.editWarnings)
                                        return false;
                                    if (permissions.editWarnings) return true;
                                    // final case, can create warnings but not edit them
                                    if (isEditing && !permissions.editWarnings)
                                        field.readOnly = true;
                                    return true;
                                case 'publicCompany':
                                    return permissions.empCreatePublicObjects;
                                case 'address1':
                                case 'address2':
                                case 'multipleCityProvince':
                                case 'multipleCPCountry':
                                case 'geolocation':
                                    if (isEditing && !permissions.geocodeempresascrud) {
                                        field.readOnly = true;
                                        field?.inputs?.forEach((input) => (input.readOnly = true));
                                    }
                                    return true;
                                default:
                                    return true;
                            }
                        });
                    });

                    resolve({
                        schema,
                        dependencyMap,
                        dynamicMap,
                    });
                })
                .catch(reject);
        });

    generateActivityOptions = () => {
        const more30 = generateRange('more', 30);
        const less30 = generateRange('less', 29);
        const less7 = generateRange('less', 7);

        return [
            {
                label: getLiteral('label_no_activity'),
                value: 1,
                valueFrom: '-1',
                valueTo: '-1',
            },
            {
                label: getLiteral('label_more_than_30days'),
                value: 2,
                valueFrom: weirdFormatDateForServer(more30.from),
                valueTo: weirdFormatDateForServer(more30.to),
            },
            {
                label: getLiteral('label_less_than_30days'),
                value: 3,
                valueFrom: weirdFormatDateForServer(less30.from),
                valueTo: weirdFormatDateForServer(less30.to),
            },
            {
                label: getLiteral('label_less_than_7days'),
                value: 4,
                valueFrom: weirdFormatDateForServer(less7.from),
                valueTo: weirdFormatDateForServer(less7.to),
            },
        ];
    };

    setFollow(id, follow, isShared = false, success, error) {
        this.context.domainManager.setFollow(
            COMPANIES.entity,
            id,
            follow,
            isShared,
            success,
            error,
        );
    }

    getSpecificEntityDefaultValues = (data, id) => {
        if (!data) return;
        const config = Context.store.getState().config;

        if (!id) {
            // cases for creation only
            if (!data.idResponsable || isEmptyObject(data.idResponsable)) {
                data['idResponsable'] = {
                    label: `${config.userData.nombre} ${config.userData.apellidos}`,
                    value: config.userData.idUsuario,
                };
            }
        }

        // someone in forcemanager decide to select the environment of the account
        // either in edition and creation if the object does not have any of them
        if (!data.IdEnvironment) {
            if (config && config.userData && config.userData.idEntorno) {
                data.idEnvironment = config.userData.idEntorno;
            }
        }

        return data;
    };

    beforeRenderValueList(options) {
        const state = Context.store.getState();

        const enviromentIds =
            state.entityFilters &&
            state.entityFilters[COMPANIES.entity] &&
            state.entityFilters[COMPANIES.entity].filters &&
            state.entityFilters[COMPANIES.entity].filters.environment &&
            state.entityFilters[COMPANIES.entity].filters.environment.value
                ? state.entityFilters[COMPANIES.entity].filters.environment.value
                : [];

        const mappedEnviromentIds = enviromentIds.reduce((obj, item) => {
            obj[item] = true;
            return obj;
        }, {});

        const filteredOptions = options.filter(
            (item) => mappedEnviromentIds[item.idparent] || !item.idparent,
        );

        return filteredOptions;
    }

    groupStates(options) {
        if (!options || options.length === 0) return;
        const hasAnyClosed = options.some((option) => {
            return (
                (getBackendBoolean(option.win) && !getBackendBoolean(option.lost)) ||
                (!getBackendBoolean(option.win) && getBackendBoolean(option.lost))
            );
        });
        if (!hasAnyClosed) return options;
        let openOptions = [];
        let closedOptions = [];
        options.map((option) => {
            if (
                (getBackendBoolean(option.win) && !getBackendBoolean(option.lost)) ||
                (!getBackendBoolean(option.win) && getBackendBoolean(option.lost))
            ) {
                if (getBackendBoolean(option.win)) option.type = 'success';
                if (getBackendBoolean(option.lost)) option.type = 'danger';
                closedOptions.push(option);
            } else {
                openOptions.push(option);
            }
        });

        return [
            { label: getLiteral('label_account_open'), options: openOptions },
            { label: getLiteral('label_account_closed'), options: closedOptions },
        ];
    }

    getExtraClassNameStateValue(option) {
        if (option.win === 'True' && option.lost === 'False') {
            return 'company-state-win';
        }
        if (option.win === 'False' && option.lost === 'True') {
            return 'company-state-lost';
        }
    }

    getRelated(id, offset, limit) {
        return this.context.domainManager.getRelatedCompanies(id, offset, limit);
    }

    isEntityReadOnly = (data) => {
        return data && data.readOnlyCompany && getBackendBoolean(data.readOnlyCompany);
    };

    getDuplicateForFields(entity, field, value) {
        return this.context.domainManager.getDuplicateForFields(entity, field, value);
    }

    listConfiguration = (schema) =>
        new Promise((resolve) => {
            this.checkListValuesWithValues().then(({ hasStates, hasRates }) => {
                resolve([
                    ...schema.filter((field) => {
                        switch (field.colId) {
                            case 'companystate':
                                return hasStates;
                            case 'tarifa':
                                return hasRates;
                            default:
                                return true;
                        }
                    }),
                ]);
            });
        });

    // check if lists have value to hide or not in table/crud
    checkListValuesWithValues = () =>
        new Promise((resolve, reject) => {
            const state = new Promise((res, rej) => {
                Context.serverListManager
                    .getList('tblEstadosEmpresa')
                    .then((result) => {
                        res(result.length > 0);
                    })
                    .catch(rej);
            });

            const rates = new Promise((res, rej) => {
                Context.serverListManager
                    .getList('ratesValueList')
                    .then((result) => {
                        res(result.length > 0);
                    })
                    .catch(rej);
            });

            Promise.all([state, rates])
                .then((result) => {
                    resolve({
                        hasStates: result[0],
                        hasRates: result[1],
                    });
                })
                .catch(reject);
        });

    formatFiltersToSend = (filters) => {
        const newFilters = translateFollowingItemForBackend(filters, COMPANIES);
        return newFilters;
    };

    afterOnChangeFilter = ({ filter, entity }) => {
        if (!filter?.filter?.id) return;
        const filterId = filter.filter.id;
        if (filterId === 'idCampaign' && !filter.value?.length) {
            const finalEntity = isCrossFilter ? entity : COMPANIES;
            const isCrossFilter = entity?.entity !== COMPANIES.entity;
            const subFiltersToRemove = ['campaignStatus'];
            Context.store.dispatch(
                Context.actions.EntityFiltersActions.deleteMultipleFilters({
                    entity: finalEntity,
                    filters: isCrossFilter
                        ? { [COMPANIES.entity]: subFiltersToRemove }
                        : subFiltersToRemove,
                }),
            );
        }
    };
}
