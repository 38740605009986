import React, { memo, useState, useMemo, useCallback, useRef } from 'react';
import { Text, Spacer, SearchBar, Divider } from 'hoi-poi-ui';
import classnames from 'classnames';
import Fuse from 'fuse.js';
import { removeAccents, stripDiacritics } from 'utils/strings';
import { getLiteral } from 'utils/getLiteral';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import AccessibilityIllustration from 'components/illustrations/AccessibilityIllustration';

const fuseOptions = {
    threshold: 0.2,
    keys: ['label'],
    getFn: (obj, path) => {
        const value = Fuse.config.getFn(obj, path);
        return removeAccents(value?.trim?.());
    },
};

import SelectHoi from 'components/SelectHoi';

const AdvancedSelectMenu = memo(
    ({ menuTitle, options, filterOptions, defaultFilter, searchPlaceholder, onChange, value }) => {
        const [filter, setFilter] = useState(defaultFilter);
        const [searchInput, setSearchInput] = useState();
        const debounce = useRef(null);

        const filteredOptions = useMemo(() => {
            let filtered = options.filter((option) => filter && option.key === filter);

            if (searchInput) {
                filtered = filtered.map((section) => {
                    const fuse = new Fuse(section.options, fuseOptions);
                    return {
                        ...section,
                        options: fuse.search(searchInput).map((result) => result.item),
                    };
                });
            }

            return filtered?.filter((option) => option?.options?.length > 0);
        }, [filter, options, searchInput]);

        const onChangeSearch = useCallback((e) => {
            clearTimeout(debounce.current);
            debounce.current = setTimeout(() => {
                const text = e?.target?.value;
                const rawText = text ? stripDiacritics(text?.toLowerCase()?.trim()) : '';
                setSearchInput(rawText);
            }, 250);
        }, []);

        const filteredOptionsMap = useMemo(() => {
            return filteredOptions?.map((option) => (
                <>
                    <Text key={option.key} className="fm-advanced-select-menu__section" medium>
                        {option.label}
                    </Text>
                    {option?.options?.map((option) => (
                        <Text
                            key={option.value}
                            className={classnames('fm-advanced-select-menu__option', {
                                'fm-advanced-select-menu__option--selected':
                                    value?.value === option.value,
                            })}
                            onClick={() => onChange(option)}
                        >
                            {option.label}
                        </Text>
                    ))}
                </>
            ));
        }, [filteredOptions, onChange, value]);

        return (
            <div className="fm-advanced-select-menu">
                <div className="fm-advanced-select-menu__header">
                    <Text className="fm-advanced-select-menu__title" type="subtitle1" medium>
                        {menuTitle}
                    </Text>
                    <Spacer y={2} />
                    <SelectHoi
                        options={filterOptions}
                        onChange={setFilter}
                        value={filter}
                        isFullWidth
                        useMenuPortal={false}
                        usePlainValue
                    />
                    <SearchBar
                        className="fm-automation-settings__search-bar"
                        placeholder={searchPlaceholder}
                        inputValue={searchInput}
                        onChange={onChangeSearch}
                        isFullWidth
                        useAsSimpleSearch
                    />
                </div>
                <Divider />
                {filteredOptionsMap.length > 0 ? (
                    <div className="fm-advanced-select-menu__options">{filteredOptionsMap}</div>
                ) : (
                    <div className="fm-advanced-select-menu__empty">
                        <AbsoluteEmpty
                            svg={<AccessibilityIllustration />}
                            title={getLiteral('label_automations_insert_field_not_found')}
                            subtitle={getLiteral('label_automations_insert_field_not_found_desc')}
                            size="popover"
                        />
                    </div>
                )}
            </div>
        );
    },
);

export default AdvancedSelectMenu;
