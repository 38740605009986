import React, { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import SelectHoi from 'components/SelectHoi';
import { Popover } from 'hoi-poi-ui';
import AdvancedSelectMenu from './AdvancedSelectMenu';
import './styles.scss';

const AdvancedSelect = memo(
    ({
        key,
        menuTitle,
        filterOptions,
        defaultFilter,
        options,
        searchPlaceholder,
        children,
        onChange,
        value,
        ...props
    }) => {
        const [visible, setVisible] = useState(false);
        const contentRef = useRef(null);

        const onClickOutside = useCallback((e) => {
            if (!contentRef.current?.contains(e.target)) {
                setVisible(false);
            }
        }, []);

        const onClick = useCallback(() => {
            setVisible((currentVisible) => !currentVisible);
        }, []);

        const onChangeField = useCallback(
            (option) => {
                onChange(option);
                setVisible(false);
            },
            [onChange],
        );

        useEffect(() => {
            document.addEventListener('mousedown', onClickOutside);
            return () => {
                document.removeEventListener('mousedown', onClickOutside);
            };
        }, [onClickOutside]);

        const popoverProps = useMemo(
            () => ({
                className: 'fm-advanced-select__popover',
                placement: 'bottomLeft',
                content: (
                    <div ref={(ref) => (contentRef.current = ref)}>
                        <AdvancedSelectMenu
                            menuTitle={menuTitle}
                            filterOptions={filterOptions}
                            defaultFilter={defaultFilter}
                            options={options}
                            searchPlaceholder={searchPlaceholder}
                            onChange={onChangeField}
                            value={value}
                        />
                    </div>
                ),
                overrides: {
                    root: {
                        visible,
                    },
                },
            }),
            [
                defaultFilter,
                filterOptions,
                menuTitle,
                onChangeField,
                options,
                searchPlaceholder,
                value,
                visible,
            ],
        );

        const overrides = useMemo(
            () => ({
                'react-select': {
                    menuIsOpen: false,
                },
            }),
            [],
        );

        return (
            <Popover {...popoverProps}>
                <div className="fm-advanced-select" onClick={onClick}>
                    {!children && (
                        <SelectHoi
                            {...props}
                            onChange={onChangeField}
                            options={options}
                            value={value}
                            overrides={overrides}
                        />
                    )}
                    {children}
                </div>
            </Popover>
        );
    },
);

export default AdvancedSelect;
